<template>
  <div
    v-if="vendor?.products"
    class="mt-5"
  >
    <v-sheet v-if="slider.length">
      <v-carousel
        cycle
        height="315"
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="(item, i) in slider"
          :key="i"
        >
          <a :href="item.action_link">
            <img
              v-if="item.image"
              :src="item.image"
              alt=""
              class="slider-image"
            >
            <img
              v-else
              :src="useDefaultSlideImage()"
              alt=""
              class="slider-image"
            >
          </a>
        </v-carousel-item>
      </v-carousel>
    </v-sheet>

    <div v-if="vendor.about">
      <SectionTitle
        :title="`About ${vendor.name}`"
        class="mt-6 mb-3"
      />

      <v-sheet>
        <v-card-text>
          <div class="about">
            <div class="about-content">
              <p>{{ vendor.about }}</p>
            </div>
          </div>
        </v-card-text>
      </v-sheet>
    </div>

    <SectionTitle
      v-if="vendor?.products?.length"
      title="Latest Products"
      class="mt-5 mb-3"
    />
    <v-container v-if="vendor?.products?.length">
      <v-sheet
        class="mb-2 mt-2 latest-products-container"
        color="transparent"
      >
        <product-slider
          :products="vendor?.products"
          @slide-action="handleProductAction($event)"
        />
      </v-sheet>
    </v-container>
    <v-row
      v-else
      align-content="center"
      class="mt-5"
      justify="center"
    >
      <v-alert
        colored-border
        type="warning"
        border="right"
        elevation="2"
      >
        <span class="no-products-text">
          This shop has no products available on sell
        </span>
      </v-alert>
    </v-row>

    <!-- Brands Slider -->
    <!--  <v-container>-->
    <!--    <v-card-title class="text-center d-block title is-section-title">Available Brands</v-card-title>-->
    <!--    <brand-slider ></brand-slider>-->
    <!--  </v-container>-->
    <!-- Brands Slider -->
  </div>
</template>

<script>
import ProductSlider from '@/components/Product/ProductSlider';
import BrandSlider from '@/components/Home/BrandSlider';
import { mapGetters } from 'vuex';
import SectionTitle from '@/components/Utils/SectionTitle';
import emptyShopCover from '@/assets/images/emptyShopCover.png';

export default {
  name: 'VendorAbout',
  components: { SectionTitle, ProductSlider, BrandSlider },

  computed: {
    ...mapGetters({
      vendor: 'vendor/currentVendor',
      slider: 'vendor/slide'
    })
  },
  methods: {
    handleProductAction(e) {
      //console.log(e);
    },

    useDefaultSlideImage(){
      return emptyShopCover;
    }
  },
};

</script>

<style lang="scss">
img.slider-image {
  width: 100%;
}

.about {
  display: flex;
  flex-direction: column;
  padding: 25px;

  &-section {
    &-title {
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: 900;
      line-height: 20px;
      text-transform: uppercase;
      text-align: center;
      color: #232323;
      overflow: hidden;
      position: relative;
      display: inline-block;

      &::after, &::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: #a5abb7;
        top: 10px;
      }

      &::before {
        right: 65%;
        margin-right: 15px;
      }

      &::after {
        left: 65%;
        margin-left: 15px;
      }
    }
  }
}

.about-content {
  font-size: clamp(1rem, 0.123vw + 0.977rem, 1.125rem);
  line-height: clamp(1.463rem, -0.037vw + 1.507rem, 1.5rem);
  text-align: center;
}

.latest-products-container {
  li {
    margin-bottom: 5px;
  }
}

.no-products-text {
  font-size: clamp(1.125rem, 0.123vw + 1.102rem, 1.25rem);
  line-height: clamp(1.625rem, -0.062vw + 1.699rem, 1.688rem);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.75);
}
</style>
