<template>
  <SliderComp
    :items="brands"
    key-prefix="brand"
    per-page="8"
    interval="2000"
    rewind
    :loop="false"
    arrow-on-outside
    autoplay
    pause-on-hover
    class="bottom-spacing"
  >
    <template #slide-item="{ item }">
      <router-link :to="{name: routeMap.products.name, query: {brand: item.slug}}">
        <v-card
          class="mx-auto"
          max-width="600"
        >
          <v-img
            :src="getImage(item)"
            aspect-ratio="1"
          />
        </v-card>
      </router-link>
    </template>
  </SliderComp>
</template>

<script>
import SliderComp from '@/components/Utils/SliderComp';
import routeMap from '@/utils/routeMap';

export default {
  name: 'BrandSlider',
  components: { SliderComp },
  props: {
    brands: Array,
  },
  data(){
    return {
      routeMap
    };
  },

  methods: {
    getImage(item) {
      return item?.logo?.url;
    }
  }

};
</script>
