<template>
  <v-card
    class="mx-auto"
    color="white"
  >
    <router-link
      :title="item.name"
      :to="routeToProductDetails()"
    >
      <v-img
        :src="item.thumbnail || defaultImage"
        aspect-ratio="1"
        class="cursor-pointer"
        @click="setProduct(item)"
      />
    </router-link>

    <v-card-text class="pt-0">
      <router-link
        :title="item.name"
        :to="routeToProductDetails()"
      >
        <v-card-title
          class="pa-0 mt-2 product-title text--primary clip-text"
          @click="setProduct(item)"
        >
          {{ item.full_name }}
        </v-card-title>
      </router-link>

      <div class="grey--text text--darken-1 clip-text mt-1">
        Sold By :
        <router-link
          :to="`/vendor/${item.product.shop.slug}`"
          class="text-decoration-none secondary--text text-capitalize"
        >
          {{ item.product.shop.name }}
        </router-link>
      </div>
    </v-card-text>

    <v-card-actions class="d-flex justify-space-between text--white">
      <v-card-subtitle class="text-h6 font-weight-bold pt-0 pl-1 pb-1">
        {{ item.price_display | price }}
      </v-card-subtitle>

      <v-btn
        :disabled="!item.stock"
        class="mr-1"
        color="secondary"
        @click="handleAction(item, 'cart')"
      >
        <v-icon size="16">
          mdi-cart
        </v-icon>
        <span class="hidden-lg-and-down">Add to Cart</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { CART_ADD, cartNameSpace } from '@/store/modules/cart/types';
import { API_BASE_URL, storeHelper } from '@/utils/helper';
import { mapActions } from 'vuex';
import sliderImage from '@/assets/images/Product/product_162_1_thumb.jpg';
import fallbackImg from '@/assets/images/emptyShopLogo.jpg';

export default {
  name: 'ProductCard',
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  emits: ['action-clicked'],

  data: () => ({
    image: sliderImage,
    defaultImage: `${API_BASE_URL}/storage/no-images.jpg` || fallbackImg,
  }),

  methods: {
    ...mapActions({
      addToCart: storeHelper(cartNameSpace, CART_ADD),
      setProduct: 'product/setProduct',
    }),

    handleAction(item, action) {
      let actionName;

      switch (action) {

        case 'view' :
          actionName = 'view';
          break;

        case 'compare' :
          actionName = 'compare';
          break;

        case 'wishlist' :
          actionName = 'wishlist';
          break;

        case 'cart' :
          actionName = 'cart';
          this.addToCart({
            item,
          });
          break;
      }
      this.$emit('action-clicked', { itemId: item, actionName });
    },

    routeToProductDetails() {
      return {
        name: 'ProductSingle',
        params: {
          variation : this.item.slug,
          product: this.item.product.slug,
          vendor: this.item.product.shop.slug
        }
      };
    }
  },
};
</script>

<style lang="scss" scoped>

.product-title {
  word-break: normal;
  font-size: clamp(0.975rem, 0.123vw + 0.852rem, 1rem);
  line-height: clamp(1.3rem, -0.012vw + 1.315rem, 1.313rem);
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  background-color: rgba(orange, .5);
  position: absolute;
  width: 100%;
}

.product-selection {
  cursor: pointer;
}

.v-btn__content {
  line-height: 5 !important;
}

span.v-btn__content span {
  font-size: 12px;
}

.v-card__title {
  justify-content: flex-start;
  align-content: flex-start;
}
</style>
